<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>
    <v-data-table
      v-model="selecionados"
      fixed-header
      show-select
      height="calc(90vh - 150px)"
      :items-per-page="30"
      :headers="headers"
      :items="produtos"
      :server-items-length="totalProdutos"
      :loading="carregandoProdutos"
      :options.sync="opcoesDatatable"
      :sort-by="parametrosBusca.ordenar"
      :sort-desc="parametrosBusca.ordemInversa"
      :page="parametrosBusca.pagina"
      class="elevation-1"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Produtos</v-toolbar-title>
          <v-btn
            color="primary darken-1"
            text
            small
            @click="atualizar"
          >
            <v-icon>mdi-cached</v-icon>
            <span class="d-none d-lg-flex">Atualizar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            small
            :disabled="carregandoProdutos"
            @click="exportar"
          >
            <v-icon>mdi-file-download</v-icon>
            <span class="d-none d-lg-flex">Exportar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="editarProduto({ id: -1 })"
          >
            <v-icon>mdi-account-plus </v-icon>
            <span class="d-none d-lg-flex">Novo Produto</span>
          </v-btn>
          <v-btn
            v-show="selecionados.length > 0"
            color="primary darken-1"
            text
            dark
            small
            @click="excluirSelecionados()"
          >
            <v-icon>mdi-delete</v-icon>
            <span class="d-none d-lg-flex">Excluir</span>
          </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="parametrosBusca.textoBusca"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            @click:append="pesquisar(opcoesDatatable)"
            @keyup.enter="pesquisar(opcoesDatatable)"
          />
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="pesquisaAvancada"
          >
            <span class="d-none d-lg-flex">Pesquisa avançada</span>
            <v-icon>mdi-magnify </v-icon>
          </v-btn>
          <pesquisa-avancada-pessoa
            ref="pesquisaAvancada"
            :parametros-pesquisa="parametrosBusca"
            :ativo="exibirPesquisaAvancada"
            @fechar="exibirPesquisaAvancada = $event"
            @pesquisar="pesquisar(opcoesDatatable)"
          />
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.codigoNacional="{ item }">
        <span>{{ item.codigoNacional | cpf_cnpj }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.endereco="{ item }">
        <texto-endereco :endereco="item.endereco" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.telefone="{ item }">
        <span>{{ item.telefone | telefone }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editarProduto(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="excluirProduto(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PesquisaAvancadaPessoa from "@/components/PesquisaAvancadaPessoa.vue";

export default {
  components: {
    PesquisaAvancadaPessoa,
  },
  data: () => ({
    selecionados: [],
    mensagens: [],
    carregandoProdutos: true,
    totalProdutos: 0,
    opcoesDatatable: {},
    exibirPesquisaAvancada: false,
    parametrosBusca: {
      pagina: 1,
      textoBusca: "",
      ordenar: "nome",
      ordemInversa: false,
      nome: "",
      codigoNacional: "",
      dataNascimento: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      cep: "",
      telefone: "",
      email: "",
      nomeContato: "",
    },
    footerProps: { "items-per-page-options": [30] },
    headers: [
      { text: "Nome", value: "nome", sortable: true },
      { text: "Marca", value: "marca", sortable: true },
      { text: "Modelo", value: "modelo", sortable: true },
      { text: "Preço de compra", value: "precoCompra", sortable: true },
      { text: "Preço de venda", value: "precoVenda", sortable: true },
      { text: "Preço do técnico", value: "precoTecnico", sortable: true },
      { text: "Unidade", value: "unidade", sortable: true },
      { text: "Estoque mínimo", value: "estoqueMinimo", sortable: true },
      { text: "Ações", value: "actions", sortable: false, width: "6em" },
    ],
    produtos: [],
  }),

  watch: {
    opcoesDatatable: {
      handler(data) {
        if (!this.carregandoProdutos) {
          this.pesquisar(data);
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query) {
      this.parametrosBusca = Object.assign(
        this.parametrosBusca,
        this.$route.query
      );
      //a cópia acima copia boolean e inteiro como string, gerando erro
      this.parametrosBusca.ordemInversa = this.$route.query.inversa == "true";
      this.parametrosBusca.pagina = parseInt(this.parametrosBusca.pagina);

      this.atualizar();
    }
  },

  methods: {
    pesquisar(data) {
      this.parametrosBusca.ordenar = data.sortBy[0];
      this.parametrosBusca.inversa = data.sortDesc[0];
      this.parametrosBusca.pagina = data.page;

      this.$router
        .push({ path: "/produtos", query: this.parametrosBusca })
        .catch((error) => {
          if (error.name == "NavigationDuplicated") {
            this.atualizar();
          } else {
            return Promise.reject(error);
          }
        });
    },

    atualizar() {
      //this.produtos = [];
      this.carregandoProdutos = true;
      this.exibirPesquisaAvancada = false;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/produtos`, {
          params: this.parametrosBusca,
        })
        .then((response) => {
          this.produtos = response.data.produtos;
          this.totalProdutos = response.data.total;
          this.carregandoProdutos = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoProdutos = false;
          return Promise.reject(error);
        });
    },

    exportar() {
      //this.produtos = [];
      this.carregandoProdutos = true;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/produtos/exportar`, {
          responseType: "blob",
          params: this.parametrosBusca,
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["Content-Type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "produtos.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
          this.carregandoProdutos = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoProdutos = false;
          return Promise.reject(error);
        });
    },

    editarProduto(item) {
      this.$router.push({ name: "produto", params: { id: item.id } });
    },

    pesquisaAvancada() {
      this.exibirPesquisaAvancada = true;
    },

    excluirProduto(produto) {
      this.$confirm(
        `Você tem certeza que deseja excluir o produto "${produto.nome}"?`
      ).then((res) => {
        if (res) {
          this.carregandoProdutos = true;
          this.$http
            .delete(`${process.env.VUE_APP_URL_API}/produtos/` + produto.id)
            .then(() => {
              const indice = this.produtos.indexOf(produto);
              this.produtos.splice(indice, 1);
              this.selecionados.splice(this.selecionados.indexOf(produto), 1);
              const idProduto = produto.id;
              this.exibirMensagem({
                mensagem:
                  'O produto "' + produto.nome + '" foi excluído com sucesso. ',
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(idProduto, indice);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.carregandoProdutos = false;
            })
            .catch((error) => {
              console.log(error);
              this.carregandoProdutos = false;
              return Promise.reject(error);
            });
        }
      });
    },

    excluirSelecionados() {
      if (this.selecionados.length == 0) return;
      if (this.selecionados.length == 1) {
        return this.excluirProduto(this.selecionados[0]);
      }

      this.$confirm(
        `Você tem certeza que deseja excluir os ${this.selecionados.length} produtos selecionados?`
      ).then((res) => {
        if (res) {
          this.carregandoProdutos = true;
          const listaID = this.selecionados.map((item) => item.id);
          this.$http
            .delete(
              `${process.env.VUE_APP_URL_API}/produtos/${listaID.join(",")}`
            )
            .then(() => {
              this.exibirMensagem({
                mensagem:
                  "Foram excluídos " + this.selecionados.length + " produtos. ",
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(listaID.join(","), 0);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.selecionados = [];
              this.atualizar();
            })
            .catch((error) => {
              console.log(error);
              this.carregandoProdutos = false;
              return Promise.reject(error);
            });
        }
      });
    },

    desfazerExclusao(idProduto, indice) {
      const listaID = idProduto;
      this.$http
        .put(`${process.env.VUE_APP_URL_API}/produtos/restaurar/` + listaID)
        .then((response) => {
          if (typeof listaID !== "string" || listaID.split(",").length == 1) {
            this.produtos.splice(indice, 0, response.data);
            this.exibirMensagem({
              mensagem: "Produto restaurado com sucesso!",
            });
          } else {
            this.exibirMensagem({
              mensagem: "Produtos restaurados com sucesso!",
            });
            this.atualizar();
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregandoProdutos = false;
          return Promise.reject(error);
        });
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }
      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
