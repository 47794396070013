"use strict";

const moment = require("moment");

const CodigoNacionalFilter = {
  install(Vue) {
    Vue.filter("cpf_cnpj", (valor) => {
      if (!valor) return "";
      valor =
        typeof valor != "string"
          ? valor.toString()
          : valor.replace(/[^\d]+/g, "");
      if (valor.length > 11) {
        valor = valor.padStart(14, "0");
        valor = valor.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        valor = valor.padStart(11, "0");
        valor = valor.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      return valor;
    });
  },
};

const Telefone = {
  install(Vue) {
    Vue.filter("telefone", (valor) => {
      if (!valor) return "";
      valor =
        typeof valor != "string"
          ? valor.toString()
          : valor.replace(/[^\d]+/g, "");
      if (valor.length > 10) {
        valor = valor.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
      } else {
        valor = valor.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      }
      return valor;
    });
  },
};

const Data = {
  install(Vue) {
    Vue.filter("data", (valor) => {
      if (valor) {
        return moment(String(valor)).format("DD/MM/YYYY");
      }
    });
  },
};

const DataHora = {
  install(Vue) {
    Vue.filter("data_hora", (valor) => {
      if (valor) {
        return moment(String(valor)).format("DD/MM/YYYY HH:mm");
      }
    });
  },
};

module.exports = {
  CodigoNacionalFilter,
  Telefone,
  Data,
  DataHora,
};
