<template>
  <v-dialog
    v-model="ativo"
    scrollable
    persistent
    width="700"
    @keydown.esc="fechar"
  >
    <v-form ref="form">
      <v-card>
        <v-card-title fixed>
          {{ tituloFormulario }}
          <v-spacer />
          <v-rating
            v-model="agendamento.prioridade"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            half-icon="mdi-star-half-full"
            hover
            length="5"
            value="3"
          />
        </v-card-title>

        <v-card-text ref="cardEvento">
          <v-container>
            <v-row dense>
              <v-col cols="6">
                <v-input-date-time
                  v-model="agendamento.dataAtendimento"
                  dense
                  label="Data de atendimento"
                  @keydown.enter="focusNext"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select-cliente
                  ref="selectCliente"
                  v-model="agendamento.cliente"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select-produto v-model="agendamento.produto" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select-tecnico v-model="agendamento.tecnico" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="agendamento.titulo"
                  dense
                  label="Resumo"
                  @keydown.enter="focusNext"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  v-model="agendamento.descricao"
                  dense
                  label="Descrição"
                  @keydown.enter="focusNext"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                class="text-h6 pb-6"
              >
                Endereço de atendimento:
              </v-col>
              <v-col cols="12">
                <v-editor-endereco :endereco="agendamento.endereco" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="fechar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="pesquisar"
          >
            Gravar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import VEditorEndereco from "@/components/Endereco.vue";
import VSelectCliente from "@/components/SelectCliente.vue";
import VSelectProduto from "@/components/SelectProduto.vue";
import VSelectTecnico from "@/components/SelectTecnico.vue";
import VInputDateTime from "@/components/InputDateTime.vue";

export default {
  components: {
    VEditorEndereco,
    VSelectCliente,
    VSelectProduto,
    VSelectTecnico,
    VInputDateTime,
  },
  props: { agendamento: { type: Object, default: null }, ativo: Boolean },
  data: () => ({
    exibirMensagemValidacao: true,
  }),
  computed: {
    tituloFormulario() {
      return (this.agendamento.id ? "Editar" : "Novo") + " agendamento";
    },
  },
  watch: {
    ativo(val) {
      if (val && this.$refs.selectCliente) {
        this.$vuetify.goTo(this.$refs.selectCliente, {
          container: this.$refs.cardEvento,
        });
      }
    },
  },
  methods: {
    pesquisar() {
      this.$emit("gravar", false);
    },
    fechar() {
      this.$emit("fechar", false);
    },
  },
};
</script>

<style>
.input-uppercase input {
  text-transform: uppercase;
}
</style>
