<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>

    <v-toolbar flat>
      <v-btn
        icon
        color="primary darken-1"
        class="hidden-xs-only"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloFormulario }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        small
        @click="carregarPapel($route.params.id)"
      >
        <v-icon>mdi-cached</v-icon>
        <span class="d-none d-lg-flex">Atualizar</span>
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        small
        :disabled="carregandoPapel"
        @click="exportar"
      >
        <v-icon>mdi-printer</v-icon>
        <span class="d-none d-lg-flex">Imprimir</span>
      </v-btn>
    </v-toolbar>

    <carregando v-if="carregandoPapel" />

    <v-form ref="form">
      <v-container
        class="mt-4"
        @change="formularioAlterado()"
      >
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="papelSelecionado.nome"
              dense
              label="Resumo"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="papelSelecionado.prioridade"
              dense
              label="Descrição"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Permissões de acesso:
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-toolbar flat>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        @click="$router.go(-1)"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="gravar"
      >
        Gravar
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import Carregando from "@/components/Carregando.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    Carregando,
  },
  data: () => ({
    modeloAlterado: false,
    mensagens: [],
    carregandoPapel: false,
    papelEmBranco: {
      codigo: "",
      ativo: true,
      nome: "",
      data_cadastro: "",
      endereco: {
        ativo: true,
        nome: "Principal",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        cep: "",
      },
      telefone: "",
      celular: "",
    },
    papelSelecionado: null,
  }),

  computed: {
    tituloFormulario() {
      return (this.papelSelecionado.id ? "Editar" : "Novo") + " Papel";
    },
  },
  created() {
    this.papelSelecionado = cloneDeep(this.papelEmBranco);
  },
  mounted() {
    this.carregarPapel(this.$route.params.id);
  },

  beforeRouteLeave(to, from, next) {
    if (this.modeloAlterado) {
      this.$confirm("Tem certeza que seja sair sem salvar?").then((res) => {
        if (res) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    formularioAlterado() {
      this.modeloAlterado = true;
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      //teste duplo porque estava dando erro de undefined em campo dinâmico de PJ
      if (index < inputs.length && inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    },
    carregarPapel(id) {
      if (id > 0) {
        this.carregandoPapel = true;
        this.$http
          .get(`${process.env.VUE_APP_URL_API}/papeis/${id}`)
          .then(async (response) => {
            this.papelSelecionado = response.data;
            this.carregandoPapel = false;
          })
          .catch((error) => {
            console.log(error);
            this.carregandoPapel = false;
            return Promise.reject(error);
          });
      } else {
        this.papelSelecionado = cloneDeep(this.papelEmBranco);
      }
    },

    exportar() {
      console.log("Não implementado.");
    },

    gravar() {
      if (!this.$refs.editorPessoa.validar()) {
        this.exibirMensagem({
          mensagem: "Corrija os erros destacados antes de prosseguir.",
          tipo: "error",
        });
        this.$vuetify.goTo(0);
        return;
      }

      if (this.papelSelecionado.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/papeis/` + this.papelSelecionado.id,
            this.papelSelecionado
          )
          .then((response) => {
            this.papelSelecionado = Object.assign(
              this.papelSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Papel atualizado com sucesso. ",
            });
            this.modeloAlterado = false;
            // if (this.indicePapelSelecionado > -1) {
            //   Object.assign(
            //     this.papeis[this.indicePapelSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.papeis.push(response.data);
            // }
            // this.fecharEditorPapel();
          });
      } else {
        this.$http
          .post(`${process.env.VUE_APP_URL_API}/papeis`, this.papelSelecionado)
          .then((response) => {
            this.papelSelecionado = Object.assign(
              this.papelSelecionado,
              response.data
            );
            this.exibirMensagem({ mensagem: "Papel inserido com sucesso. " });
            this.modeloAlterado = false;
            // if (this.indicePapelSelecionado > -1) {
            //   Object.assign(
            //     this.papeis[this.indicePapelSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.papeis.push(response.data);
            // }
            // this.fecharEditorPapel();
          });
      }

      this.$vuetify.goTo(0);
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }

      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
