import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import Register from "../views/Register";
import UserBoard from "../views/UserBoard";
import Calendario from "../views/Calendario";
import Admin from "../views/Admin";
import LoggedInLayout from "../views/LoggedInLayout";
import Agendamento from "../views/Agendamento";
import Cliente from "../views/Cliente";
import Empresa from "../views/Empresa";
import Fornecedor from "../views/Fornecedor";
import MovimentoEstoque from "../views/MovimentoEstoque";
import OrdemServico from "../views/OrdemServico";
import Papel from "../views/Papel";
import Produto from "../views/Produto";
import Tecnico from "../views/Tecnico";
import Usuario from "../views/Usuario";
import ListaAgendamento from "../views/ListaAgendamento";
import ListaCliente from "../views/ListaCliente";
import ListaEmpresa from "../views/ListaEmpresa";
import ListaFornecedor from "../views/ListaFornecedor";
import ListaMovimentoEstoque from "../views/ListaMovimentoEstoque";
import ListaOrdemServico from "../views/ListaOrdemServico";
import ListaPapel from "../views/ListaPapel";
import ListaProduto from "../views/ListaProduto";
import ListaUsuario from "../views/ListaUsuario";
import ListaTecnico from "../views/ListaTecnico";
import Erro404 from "../views/Erro404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login/:oauth?",
    name: "login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      guest: true,
    },
  },
  {
    path: "",
    component: LoggedInLayout,
    children: [
      {
        path: "/dashboard",
        name: "userboard",
        component: UserBoard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/calendario",
        name: "calendario",
        component: Calendario,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin",
        name: "admin",
        component: Admin,
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
      },
      {
        path: "/agendamentos",
        name: "agendamentos",
        component: ListaAgendamento,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/agendamentos/:id",
        name: "agendamento",
        component: Agendamento,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/clientes",
        name: "clientes",
        component: ListaCliente,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/clientes/:id",
        name: "cliente",
        component: Cliente,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/empresas",
        name: "empresas",
        component: ListaEmpresa,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/empresas/:id",
        name: "empresa",
        component: Empresa,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/fornecedores",
        name: "fornecedores",
        component: ListaFornecedor,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/fornecedores/:id",
        name: "fornecedor",
        component: Fornecedor,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/movimentos-estoque",
        name: "movimentosEstoque",
        component: ListaMovimentoEstoque,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/movimentos-estoque/:id",
        name: "movimentoEstoque",
        component: MovimentoEstoque,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/ordens-servico",
        name: "ordensServico",
        component: ListaOrdemServico,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/ordens-servico/:id",
        name: "ordemServico",
        component: OrdemServico,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/papeis",
        name: "papeis",
        component: ListaPapel,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/papeis/:id",
        name: "papel",
        component: Papel,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/produtos",
        name: "produtos",
        component: ListaProduto,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/produtos/:id",
        name: "produto",
        component: Produto,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/tecnicos",
        name: "tecnicos",
        component: ListaTecnico,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/tecnicos/:id",
        name: "tecnico",
        component: Tecnico,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/usuarios",
        name: "usuarios",
        component: ListaUsuario,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/usuarios/:id",
        name: "usuario",
        component: Usuario,
        meta: {
          requiresAuth: true,
          is_admin: false,
        },
      },
      {
        path: "/*",
        name: "erro404",
        component: Erro404,
        meta: {
          requiresAuth: false,
          is_admin: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: {
          nextUrl: to.fullPath,
        },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("usuario"));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({
            name: "userboard",
          });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({
        name: "userboard",
      });
    }
  } else {
    next();
  }
});

export default router;
