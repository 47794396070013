import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Axios from "axios";
import VueMask from "v-mask";
import filters from "./helper/filters";

Axios.interceptors.request.use(function (config) {
  if (!config.url.includes("viacep.com.br")) {
    const token = localStorage.getItem("jwt");
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

Vue.prototype.$http = Axios;
Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(filters.CodigoNacionalFilter);
Vue.use(filters.Telefone);
Vue.use(filters.Data);
Vue.use(filters.DataHora);

import "@/assets/global.css";

const app = new Vue({
  vuetify,
  router,
  render: (h) => h(App),
});

app.$mount("#app");
