<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>
    <v-data-table
      v-model="selecionados"
      fixed-header
      show-select
      height="calc(90vh - 150px)"
      :items-per-page="30"
      :headers="headers"
      :items="papeis"
      :server-items-length="totalPapeis"
      :loading="carregandoPapeis"
      :options.sync="opcoesDatatable"
      :sort-by="parametrosBusca.ordenar"
      :sort-desc="parametrosBusca.ordemInversa"
      :page="parametrosBusca.pagina"
      class="elevation-1"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Papéis</v-toolbar-title>
          <v-btn
            color="primary darken-1"
            text
            small
            @click="atualizar"
          >
            <v-icon>mdi-cached</v-icon>
            <span class="d-none d-lg-flex">Atualizar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            small
            :disabled="carregandoPapeis"
            @click="exportar"
          >
            <v-icon>mdi-file-download</v-icon>
            <span class="d-none d-lg-flex">Exportar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="editarPapel({ id: -1 })"
          >
            <v-icon>mdi-account-plus </v-icon>
            <span class="d-none d-lg-flex">Novo Papel</span>
          </v-btn>
          <v-btn
            v-show="selecionados.length > 0"
            color="primary darken-1"
            text
            dark
            small
            @click="excluirSelecionados()"
          >
            <v-icon>mdi-delete</v-icon>
            <span class="d-none d-lg-flex">Excluir</span>
          </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="parametrosBusca.textoBusca"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            @click:append="pesquisar(opcoesDatatable)"
            @keyup.enter="pesquisar(opcoesDatatable)"
          />
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="pesquisaAvancada"
          >
            <span class="d-none d-lg-flex">Pesquisa avançada</span>
            <v-icon>mdi-magnify </v-icon>
          </v-btn>
          <pesquisa-avancada-pessoa
            ref="pesquisaAvancada"
            :parametros-pesquisa="parametrosBusca"
            :ativo="exibirPesquisaAvancada"
            @fechar="exibirPesquisaAvancada = $event"
            @pesquisar="pesquisar(opcoesDatatable)"
          />
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editarPapel(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="excluirPapel(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PesquisaAvancadaPessoa from "@/components/PesquisaAvancadaPessoa.vue";

export default {
  components: {
    PesquisaAvancadaPessoa,
  },
  data: () => ({
    selecionados: [],
    mensagens: [],
    carregandoPapeis: true,
    totalPapeis: 0,
    opcoesDatatable: {},
    exibirPesquisaAvancada: false,
    parametrosBusca: {
      pagina: 1,
      textoBusca: "",
      ordenar: "nome",
      ordemInversa: false,
      nome: "",
      codigoNacional: "",
      dataNascimento: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      cep: "",
      telefone: "",
      email: "",
      nomeContato: "",
    },
    footerProps: { "items-per-page-options": [30] },
    headers: [
      { text: "Nome", value: "nome", sortable: true, width: "30em" },
      { text: "Ações", value: "actions", sortable: false, width: "6em" },
    ],
    papeis: [],
  }),

  watch: {
    opcoesDatatable: {
      handler(data) {
        if (!this.carregandoPapeis) {
          this.pesquisar(data);
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query) {
      this.parametrosBusca = Object.assign(
        this.parametrosBusca,
        this.$route.query
      );
      //a cópia acima copia boolean e inteiro como string, gerando erro
      this.parametrosBusca.ordemInversa = this.$route.query.inversa == "true";
      this.parametrosBusca.pagina = parseInt(this.parametrosBusca.pagina);

      this.atualizar();
    }
  },

  methods: {
    pesquisar(data) {
      this.parametrosBusca.ordenar = data.sortBy[0];
      this.parametrosBusca.inversa = data.sortDesc[0];
      this.parametrosBusca.pagina = data.page;

      this.$router
        .push({ path: "/papeis", query: this.parametrosBusca })
        .catch((error) => {
          if (error.name == "NavigationDuplicated") {
            this.atualizar();
          } else {
            return Promise.reject(error);
          }
        });
    },

    atualizar() {
      //this.papeis = [];
      this.carregandoPapeis = true;
      this.exibirPesquisaAvancada = false;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/papeis`, {
          params: this.parametrosBusca,
        })
        .then((response) => {
          this.papeis = response.data.papeis;
          this.totalPapeis = response.data.total;
          this.carregandoPapeis = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoPapeis = false;
          return Promise.reject(error);
        });
    },

    exportar() {
      //this.papeis = [];
      this.carregandoPapeis = true;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/papeis/exportar`, {
          responseType: "blob",
          params: this.parametrosBusca,
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["Content-Type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "papeis.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
          this.carregandoPapeis = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoPapeis = false;
          return Promise.reject(error);
        });
    },

    editarPapel(item) {
      this.$router.push({ name: "papel", params: { id: item.id } });
    },

    pesquisaAvancada() {
      this.exibirPesquisaAvancada = true;
    },

    excluirPapel(papel) {
      this.$confirm(
        `Você tem certeza que deseja excluir o papel "${papel.nome}"?`
      ).then((res) => {
        if (res) {
          this.carregandoPapeis = true;
          this.$http
            .delete(`${process.env.VUE_APP_URL_API}/papeis/` + papel.id)
            .then(() => {
              const indice = this.papeis.indexOf(papel);
              this.papeis.splice(indice, 1);
              this.selecionados.splice(this.selecionados.indexOf(papel), 1);
              const idPapel = papel.id;
              this.exibirMensagem({
                mensagem:
                  'O papel "' + papel.nome + '" foi excluído com sucesso. ',
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(idPapel, indice);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.carregandoPapeis = false;
            })
            .catch((error) => {
              console.log(error);
              this.carregandoPapeis = false;
              return Promise.reject(error);
            });
        }
      });
    },

    excluirSelecionados() {
      if (this.selecionados.length == 0) return;
      if (this.selecionados.length == 1) {
        return this.excluirPapel(this.selecionados[0]);
      }

      this.$confirm(
        `Você tem certeza que deseja excluir os ${this.selecionados.length} papéis selecionados?`
      ).then((res) => {
        if (res) {
          this.carregandoPapeis = true;
          const listaID = this.selecionados.map((item) => item.id);
          this.$http
            .delete(
              `${process.env.VUE_APP_URL_API}/papeis/${listaID.join(",")}`
            )
            .then(() => {
              this.exibirMensagem({
                mensagem:
                  "Foram excluídos " + this.selecionados.length + " papéis. ",
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(listaID.join(","), 0);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.selecionados = [];
              this.atualizar();
            })
            .catch((error) => {
              console.log(error);
              this.carregandoPapeis = false;
              return Promise.reject(error);
            });
        }
      });
    },

    desfazerExclusao(idPapel, indice) {
      const listaID = idPapel;
      this.$http
        .put(`${process.env.VUE_APP_URL_API}/papeis/restaurar/` + listaID)
        .then((response) => {
          if (typeof listaID !== "string" || listaID.split(",").length == 1) {
            this.papeis.splice(indice, 0, response.data);
            this.exibirMensagem({ mensagem: "Papel restaurado com sucesso!" });
          } else {
            this.exibirMensagem({
              mensagem: "Papéis restaurados com sucesso!",
            });
            this.atualizar();
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregandoPapeis = false;
          return Promise.reject(error);
        });
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }
      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
