import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
//import ptBR from '../assets/lang/ptBR'
import VuetifyConfirm from "./vuetify-confirm-plugin";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang: {
    //   locales: {
    //     ptBR,
    //   },
    current: "pt",
  },
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});

Vue.use(VuetifyConfirm, {
  vuetify,
});

export default vuetify;
