<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>
    <v-data-table
      v-model="selecionados"
      fixed-header
      show-select
      height="calc(90vh - 150px)"
      :items-per-page="30"
      :headers="computedHeaders"
      :items="agendamentos"
      :server-items-length="totalAgendamentos"
      :loading="carregandoAgendamentos"
      :options.sync="opcoesDatatable"
      :sort-by="parametrosBusca.ordenar"
      :sort-desc="parametrosBusca.ordemInversa"
      :page="parametrosBusca.pagina"
      class="elevation-1"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="!embutido"
          flat
        >
          <v-toolbar-title>Agendamentos</v-toolbar-title>
          <v-btn
            color="primary darken-1"
            text
            small
            @click="atualizar"
          >
            <v-icon>mdi-cached</v-icon>
            <span class="d-none d-lg-flex">Atualizar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            small
            :disabled="carregandoAgendamentos"
            @click="exportar"
          >
            <v-icon>mdi-file-download</v-icon>
            <span class="d-none d-lg-flex">Exportar</span>
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="editarAgendamento({ id: -1 })"
          >
            <v-icon>mdi-account-plus </v-icon>
            <span class="d-none d-lg-flex">Novo Agendamento</span>
          </v-btn>
          <v-btn
            v-show="selecionados.length > 0"
            color="primary darken-1"
            text
            dark
            small
            @click="excluirSelecionados()"
          >
            <v-icon>mdi-delete</v-icon>
            <span class="d-none d-lg-flex">Excluir</span>
          </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="parametrosBusca.textoBusca"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            @click:append="pesquisar(opcoesDatatable)"
            @keyup.enter="pesquisar(opcoesDatatable)"
          />
          <v-btn
            color="primary darken-1"
            text
            dark
            small
            @click="pesquisaAvancada"
          >
            <span class="d-none d-lg-flex">Pesquisa avançada</span>
            <v-icon>mdi-magnify </v-icon>
          </v-btn>
          <pesquisa-avancada-pessoa
            ref="pesquisaAvancada"
            :parametros-pesquisa="parametrosBusca"
            :ativo="exibirPesquisaAvancada"
            @fechar="exibirPesquisaAvancada = $event"
            @pesquisar="pesquisar(opcoesDatatable)"
          />
        </v-toolbar>
        <v-form ref="form">
          <v-container
            class="mt-4"
            @change="atualizar()"
          >
            <v-row dense>
              <v-col cols="4">
                <v-input-date
                  v-model="parametrosBusca.dataInicial"
                  label="A partir de"
                  @keydown.enter="atualizar"
                />
              </v-col>
              <v-col cols="4">
                <v-input-date
                  v-model="parametrosBusca.dataFinal"
                  label="até"
                  @keydown.enter="atualizar"
                />
              </v-col>
              <v-col
                v-if="!produto"
                cols="4"
              >
                <v-select-produto
                  v-model="produtoSelecionado"
                  @keydown.enter="atualizar"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.dataAtendimento="{ item }">
        <span>{{ item.dataAtendimento | data_hora }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.cliente.nome="{ item }">
        <span>{{ item.cliente.codigoNacional | cpf_cnpj }}</span><br>
        <span>{{ item.cliente.nome }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.produto.nome="{ item }">
        <span>{{ item.produto.nome }}</span><br>
        <span>{{ item.produto.marca }}</span>
        <br>
        <span>{{ item.produto.modelo }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.endereco="{ item }">
        <texto-endereco :endereco="item.endereco" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.cliente.telefone="{ item }">
        <span>{{ item.cliente.telefone | telefone }}</span><br>
        <span>{{ item.cliente.celular | telefone }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editarAgendamento(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="excluirAgendamento(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TextoEndereco from "@/components/TextoEndereco.vue";
import PesquisaAvancadaPessoa from "@/components/PesquisaAvancadaPessoa.vue";
import VSelectProduto from "@/components/SelectProduto.vue";
import VInputDate from "@/components/InputDate.vue";

export default {
  components: {
    TextoEndereco,
    PesquisaAvancadaPessoa,
    VInputDate,
    VSelectProduto,
  },
  props: {
    cliente: { type: Object, default: null },
    tecnico: { type: Object, default: null },
    produto: { type: Object, default: null },
    embutido: Boolean,
  },
  data: () => ({
    selecionados: [],
    mensagens: [],
    carregandoAgendamentos: true,
    totalAgendamentos: 0,
    opcoesDatatable: {},
    exibirPesquisaAvancada: false,
    produtoSelecionado: null,
    parametrosBusca: {
      pagina: 1,
      textoBusca: null,
      ordenar: "dataAtendimento",
      ordemInversa: true,
      id_cliente: null,
      id_tecnico: null,
      id_produto: null,
      dataInicial: null,
      dataFinal: null,
      nome: "",
      codigoNacional: "",
      dataNascimento: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      cep: "",
      telefone: "",
      email: "",
      nomeContato: "",
    },
    footerProps: { "items-per-page-options": [30] },
    headers: [
      {
        text: "Agendado para",
        value: "dataAtendimento",
        sortable: true,
        width: "13em",
      },
      { text: "Cliente", value: "cliente.nome", sortable: true, width: "15em" },
      { text: "Técnico", value: "tecnico.nome", sortable: true, width: "15em" },
      { text: "Produto", value: "produto.nome", sortable: true, width: "10em" },
      { text: "Resumo", value: "titulo", sortable: true },
      { text: "Endereço de atendimento", value: "endereco", sortable: false },
      { text: "Telefone do cliente", value: "cliente.telefone", width: "12em" },
      { text: "Ações", value: "actions", sortable: false, width: "6em" },
    ],
    agendamentos: [],
  }),

  computed: {
    computedHeaders() {
      var headersFiltrado = this.headers;

      if (this.cliente) {
        headersFiltrado = headersFiltrado.filter(
          (item) => !item.value.startsWith("cliente")
        );
      }
      if (this.tecnico) {
        headersFiltrado = headersFiltrado.filter(
          (item) => !item.value.startsWith("tecnico")
        );
      }
      if (this.produto) {
        headersFiltrado = headersFiltrado.filter(
          (item) => !item.value.startsWith("produto")
        );
      }

      return headersFiltrado;
    },
  },

  watch: {
    opcoesDatatable: {
      handler(data) {
        if (!this.carregandoAgendamentos) {
          this.pesquisar(data);
        }
      },
      deep: true,
    },
    cliente(newValue) {
      this.parametrosBusca.id_cliente = newValue.id;
      this.atualizar();
    },
    tecnico(newValue) {
      this.parametrosBusca.id_tecnico = newValue.id;
      this.atualizar();
    },
    produto(newValue) {
      this.parametrosBusca.id_produto = newValue.id;
      this.atualizar();
    },
  },

  mounted() {
    if (this.$route.query) {
      this.parametrosBusca = Object.assign(
        this.parametrosBusca,
        this.$route.query
      );
      //a cópia acima copia boolean e inteiro como string, gerando erro
      this.parametrosBusca.ordemInversa = this.$route.query.inversa == "true";
      this.parametrosBusca.pagina = parseInt(this.parametrosBusca.pagina);
      this.parametrosBusca.id_cliente = this.cliente ? this.cliente.id : null;
      this.parametrosBusca.id_tecnico = this.tecnico ? this.tecnico.id : null;
      this.parametrosBusca.id_produto = this.produto ? this.produto.id : null;

      this.atualizar();
    }
  },

  methods: {
    pesquisar(data) {
      this.parametrosBusca.ordenar = data.sortBy[0];
      this.parametrosBusca.inversa = data.sortDesc[0];
      this.parametrosBusca.pagina = data.page;

      this.$router
        .push({ path: "/agendamentos", query: this.parametrosBusca })
        .catch((error) => {
          if (error.name == "NavigationDuplicated") {
            this.atualizar();
          } else {
            return Promise.reject(error);
          }
        });
    },

    atualizar() {
      //this.agendamentos = [];
      this.carregandoAgendamentos = true;
      this.exibirPesquisaAvancada = false;
      if (this.produtoSelecionado && !this.produto) {
        this.parametrosBusca.id_produto = this.produtoSelecionado.id;
      }
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/agendamentos`, {
          params: this.parametrosBusca,
        })
        .then((response) => {
          this.agendamentos = response.data.agendamentos;
          this.totalAgendamentos = response.data.total;
          this.carregandoAgendamentos = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoAgendamentos = false;
          return Promise.reject(error);
        });
    },

    exportar() {
      //this.agendamentos = [];
      this.carregandoAgendamentos = true;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/agendamentos/exportar`, {
          responseType: "blob",
          params: this.parametrosBusca,
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["Content-Type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "agendamentos.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
          this.carregandoAgendamentos = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoAgendamentos = false;
          return Promise.reject(error);
        });
    },

    editarAgendamento(item) {
      this.$router.push({ name: "agendamento", params: { id: item.id } });
    },

    pesquisaAvancada() {
      this.exibirPesquisaAvancada = true;
    },

    excluirAgendamento(agendamento) {
      this.$confirm(
        `Você tem certeza que deseja excluir o agendamento do cliente "${agendamento.cliente.nome}"?`
      ).then((res) => {
        if (res) {
          this.carregandoAgendamentos = true;
          this.$http
            .delete(
              `${process.env.VUE_APP_URL_API}/agendamentos/` + agendamento.id
            )
            .then(() => {
              const indice = this.agendamentos.indexOf(agendamento);
              this.agendamentos.splice(indice, 1);
              this.selecionados.splice(
                this.selecionados.indexOf(agendamento),
                1
              );
              const idAgendamento = agendamento.id;
              this.exibirMensagem({
                mensagem:
                  'O agendamento do cliente "' +
                  agendamento.cliente.nome +
                  '" foi excluído com sucesso. ',
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(idAgendamento, indice);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.carregandoAgendamentos = false;
            })
            .catch((error) => {
              console.log(error);
              this.carregandoAgendamentos = false;
              return Promise.reject(error);
            });
        }
      });
    },

    excluirSelecionados() {
      if (this.selecionados.length == 0) return;
      if (this.selecionados.length == 1) {
        return this.excluirAgendamento(this.selecionados[0]);
      }

      this.$confirm(
        `Você tem certeza que deseja excluir os ${this.selecionados.length} agendamentos selecionados?`
      ).then((res) => {
        if (res) {
          this.carregandoAgendamentos = true;
          const listaID = this.selecionados.map((item) => item.id);
          this.$http
            .delete(
              `${process.env.VUE_APP_URL_API}/agendamentos/${listaID.join(",")}`
            )
            .then(() => {
              this.exibirMensagem({
                mensagem:
                  "Foram excluídos " +
                  this.selecionados.length +
                  " agendamentos. ",
                acao: "Desfazer ação.",
                callback: (mensagem) => {
                  this.desfazerExclusao(listaID.join(","), 0);
                  this.mensagens.splice(this.mensagens.indexOf(mensagem), 1);
                },
              });
              this.selecionados = [];
              this.atualizar();
            })
            .catch((error) => {
              console.log(error);
              this.carregandoAgendamentos = false;
              return Promise.reject(error);
            });
        }
      });
    },

    desfazerExclusao(idAgendamento, indice) {
      const listaID = idAgendamento;
      this.$http
        .put(`${process.env.VUE_APP_URL_API}/agendamentos/restaurar/` + listaID)
        .then((response) => {
          if (typeof listaID !== "string" || listaID.split(",").length == 1) {
            this.agendamentos.splice(indice, 0, response.data);
            this.exibirMensagem({
              mensagem: "Agendamento restaurado com sucesso!",
            });
          } else {
            this.exibirMensagem({
              mensagem: "Agendamentos restaurados com sucesso!",
            });
            this.atualizar();
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregandoAgendamentos = false;
          return Promise.reject(error);
        });
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }
      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
