<template>
  <v-app>
    <v-snackbar
      v-model="exibirMensagemErro"
      top
      :width="'95%'"
      :color="'error'"
    >
      <span
        v-for="texto in mensagens"
        :key="texto"
        class="d-block"
      >{{
        texto
      }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="dark"
          text
          v-bind="attrs"
          @click="exibirMensagemErro = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      exibirMensagemErro: false,
      mensagens: [],
      axiosInterceptor: null,
    };
  },

  mounted: function () {
    this.axiosInterceptor = this.$http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // check if it's a server error
        if (!error.response) {
          this.exibirMensagem([
            "Não foi possível estabelecer a comunicação com o servidor. ",
            "Verifique se você está conectado na internet.",
          ]);
          return Promise.reject(error);
        }

        switch (error.response.status) {
          case 401:
            console.error(error.response.status, error.message);
            localStorage.removeItem("jwt");
            localStorage.removeItem("usuario");
            this.$router.push("/login").catch(() => {});
            break;

          case 404:
            console.error(error.response.status, error.message);
            this.exibirMensagem(["O recurso solicitado não existe."]);
            break;

          default:
            console.error(error.response.status, error.message);
            if (!!error.response.data && !!error.response.data.mensagem) {
              this.exibirMensagem([error.response.data.mensagem]);
            } else if (error.response.data.erros) {
              this.exibirMensagem(error.response.data.erros);
            } else {
              console.log(error.response.data);

              if (error.response.data instanceof Blob) {
                if (
                  (error.response.data.type === "text/html" ||
                    error.response.data.type === "text/plain") &&
                  error.response.data.size < 1024
                ) {
                  const reader = new FileReader();
                  reader.addEventListener("loadend", (e) => {
                    const mensagem = e.target.result;
                    this.exibirMensagem([mensagem]);
                  });
                  reader.readAsText(error.response.data);
                } else {
                  this.exibirMensagem([
                    "Ocorreu um erro ao processar a requisição.",
                  ]);
                }
              } else {
                this.exibirMensagem([error.response.data]);
              }
            }
        }
        return Promise.reject(error);
      }
    );
  },

  unmounted: function () {
    this.$http.interceptors.response.eject(this.axiosInterceptor);
  },

  methods: {
    exibirMensagem: function (mensagens) {
      this.mensagens = mensagens;
      this.exibirMensagemErro = true;
    },
  },
};
</script>
