<template>
  <v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-sheet
              class="d-flex align-center justify-center pa-4 mx-auto"
              max-width="550"
              min-height="150"
            >
              <div class="text-h3 mb-1 text-center">
                Gestão VIP
              </div>
            </v-sheet>
            <v-card class="elevation-12">
              <v-card-text>
                <v-form>
                  <div class="text-h6 mb-3 text-center">
                    Criar uma conta no Gestão VIP
                  </div>
                  <v-text-field
                    v-model="username"
                    prepend-icon="mdi-account"
                    name="username"
                    label="Usuário"
                    type="text"
                  />
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-account"
                    name="name"
                    label="Nome"
                    type="text"
                  />
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account"
                    name="email"
                    label="e-mail"
                    type="email"
                  />
                  <v-text-field
                    id="senha"
                    v-model="senha"
                    prepend-icon="mdi-lock"
                    name="senha"
                    label="Senha"
                    type="senha"
                  />
                  <v-text-field
                    id="senha"
                    v-model="senha"
                    prepend-icon="mdi-lock"
                    name="senha"
                    label="Confirmar senha"
                    type="senha"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="handleSubmit"
                >
                  Criar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: { nextUrl: { type: String, default: "" } },
  data() {
    return {
      name: "",
      email: "",
      senha: "",
      senha_confirmation: "",
      is_admin: null,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      if (this.senha === this.senha_confirmation && this.senha.length > 0) {
        let url = `${process.env.VUE_APP_URL_API}/register`;
        if (this.is_admin != null || this.is_admin == 1)
          url = `${process.env.VUE_APP_URL_API}/register-admin`;
        this.$http
          .post(url, {
            name: this.name,
            email: this.email,
            senha: this.senha,
            is_admin: this.is_admin,
          })
          .then((response) => {
            localStorage.setItem("usuario", JSON.stringify(response.data.user));
            localStorage.setItem("jwt", response.data.token);

            if (localStorage.getItem("jwt") != null) {
              this.$emit("loggedIn");
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl);
              } else {
                this.$router.push("/");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.senha = "";
        this.senhaConfirm = "";

        return alert("Passwords do not match");
      }
    },
  },
};
</script>
