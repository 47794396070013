<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>

    <v-toolbar flat>
      <v-btn
        icon
        color="primary darken-1"
        class="hidden-xs-only"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloFormulario }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        small
        @click="carregarProduto($route.params.id)"
      >
        <v-icon>mdi-cached</v-icon>
        <span class="d-none d-lg-flex">Atualizar</span>
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        small
        :disabled="carregandoProduto"
        @click="exportar"
      >
        <v-icon>mdi-printer</v-icon>
        <span class="d-none d-lg-flex">Imprimir</span>
      </v-btn>
    </v-toolbar>

    <carregando v-if="carregandoProduto" />

    <v-form ref="form">
      <v-container
        class="mt-4"
        @change="formularioAlterado()"
      >
        <v-row dense>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Dados do produto:
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.nome"
              dense
              label="Nome"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.descricao"
              dense
              label="Descrição"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.marca"
              dense
              label="Marca"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.modelo"
              dense
              label="Modelo"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.precoCompra"
              dense
              label="Preço de compra"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.precoVenda"
              dense
              label="Preço de venda"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.precoTecnico"
              dense
              label="Preço do técnico"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.unidade"
              dense
              label="Unidade"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="produtoSelecionado.estoqueMinimo"
              dense
              label="Estoque mínimo"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-toolbar flat>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        @click="$router.go(-1)"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="gravar"
      >
        Gravar
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import Carregando from "@/components/Carregando.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    Carregando,
  },
  data: () => ({
    modeloAlterado: false,
    mensagens: [],
    carregandoProduto: false,
    produtoEmBranco: {
      codigo: "",
      ativo: true,
      nome: "",
      data_cadastro: "",
      endereco: {
        ativo: true,
        nome: "Principal",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        cep: "",
      },
      telefone: "",
      celular: "",
    },
    produtoSelecionado: null,
  }),

  computed: {
    tituloFormulario() {
      return (this.produtoSelecionado.id ? "Editar" : "Novo") + " Produto";
    },
  },
  created() {
    this.produtoSelecionado = cloneDeep(this.produtoEmBranco);
  },
  mounted() {
    this.carregarProduto(this.$route.params.id);
  },

  beforeRouteLeave(to, from, next) {
    if (this.modeloAlterado) {
      this.$confirm("Tem certeza que seja sair sem salvar?").then((res) => {
        if (res) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    formularioAlterado() {
      this.modeloAlterado = true;
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      //teste duplo porque estava dando erro de undefined em campo dinâmico de PJ
      if (index < inputs.length && inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    },
    carregarProduto(id) {
      if (id > 0) {
        this.carregandoProduto = true;
        this.$http
          .get(`${process.env.VUE_APP_URL_API}/produtos/${id}`)
          .then(async (response) => {
            this.produtoSelecionado = response.data;
            this.carregandoProduto = false;
          })
          .catch((error) => {
            console.log(error);
            this.carregandoProduto = false;
            return Promise.reject(error);
          });
      } else {
        this.produtoSelecionado = cloneDeep(this.produtoEmBranco);
      }
    },

    exportar() {
      console.log("Não implementado.");
    },

    gravar() {
      if (!this.$refs.editorPessoa.validar()) {
        this.exibirMensagem({
          mensagem: "Corrija os erros destacados antes de prosseguir.",
          tipo: "error",
        });
        this.$vuetify.goTo(0);
        return;
      }

      if (this.produtoSelecionado.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/produtos/` +
              this.produtoSelecionado.id,
            this.produtoSelecionado
          )
          .then((response) => {
            this.produtoSelecionado = Object.assign(
              this.produtoSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Produto atualizado com sucesso. ",
            });
            this.modeloAlterado = false;
            // if (this.indiceProdutoSelecionado > -1) {
            //   Object.assign(
            //     this.produtos[this.indiceProdutoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.produtos.push(response.data);
            // }
            // this.fecharEditorProduto();
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_URL_API}/produtos`,
            this.produtoSelecionado
          )
          .then((response) => {
            this.produtoSelecionado = Object.assign(
              this.produtoSelecionado,
              response.data
            );
            this.exibirMensagem({ mensagem: "Produto inserido com sucesso. " });
            this.modeloAlterado = false;
            // if (this.indiceProdutoSelecionado > -1) {
            //   Object.assign(
            //     this.produtos[this.indiceProdutoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.produtos.push(response.data);
            // }
            // this.fecharEditorProduto();
          });
      }

      this.$vuetify.goTo(0);
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }

      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
