<template>
  <v-container class="ma-0 pa-0">
    <v-row dense>
      <v-col cols="6">
        <v-text-field-cep
          v-model="endereco.cep"
          dense
          label="CEP"
          @keyup="buscaCEP"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="8">
        <v-text-field
          v-model="endereco.logradouro"
          dense
          label="Endereço"
          @keydown.enter="focusNext"
        />
      </v-col>
      <v-col cols="4">
        <!-- Parece errado usar toUpperCase() no número, mas ele pode ser S/N -->
        <v-text-field
          dense
          label="Número"
          :value="endereco.numero.toUpperCase()"
          @input="endereco.numero = $event.toUpperCase()"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="endereco.complemento"
          dense
          label="Complemento"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="endereco.bairro"
          dense
          label="Bairro"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="8">
        <v-text-field
          v-model="endereco.municipio"
          dense
          label="Município"
          @keydown.enter="focusNext"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-mask="'AA'"
          dense
          label="UF"
          :value="endereco.uf.toUpperCase()"
          @input="endereco.uf = $event.toUpperCase()"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="endereco.pontoReferencia"
          dense
          label="Ponto de referência"
          @keydown.enter="focusNext"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { obrigatorio, cep } from "@/helper/validadores";
import VTextFieldCep from "./Cep.vue";

export default {
  components: {
    VTextFieldCep,
  },
  props: { endereco: { type: Object, default: null } },
  data: () => ({
    regrasCEP: [obrigatorio, cep],
  }),
  methods: {
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    buscaCEP() {
      if (this.endereco.cep && this.endereco.cep.length == 10) {
        var cep = this.endereco.cep.replaceAll(".", "").replaceAll("-", "");
        this.$http
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            if (response.status == 200 && !response.data.erro) {
              if (!this.endereco.logradouro) {
                this.endereco.logradouro = response.data.logradouro;
              }
              if (!this.endereco.bairro) {
                this.endereco.bairro = response.data.bairro;
              }
              // if (!this.endereco.complemento) {
              //   this.endereco.complemento = response.data.complemento;
              // }
              if (!this.endereco.municipio) {
                this.endereco.municipio = response.data.localidade;
              }
              if (!this.endereco.uf) {
                this.endereco.uf = response.data.uf;
              }
            }
          });
      }
    },
  },
};
</script>
