<template>
  <div v-if="endereco.logradouro">
    <span>{{ endereco.logradouro }}, {{ endereco.numero }}.
      {{ endereco.complemento }}</span>
    <br><span>{{ endereco.bairro }}. {{ endereco.municipio }} - {{ endereco.uf }}</span>
  </div>
</template>

<script>
export default {
  props: { endereco: { type: String, default: "" } },
};
</script>
