<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>

    <v-toolbar flat>
      <v-btn
        icon
        color="primary darken-1"
        class="hidden-xs-only"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloFormulario }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        small
        @click="carregarAgendamento($route.params.id)"
      >
        <v-icon>mdi-cached</v-icon>
        <span class="d-none d-lg-flex">Atualizar</span>
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        small
        :disabled="carregandoAgendamento"
        @click="exportar"
      >
        <v-icon>mdi-printer</v-icon>
        <span class="d-none d-lg-flex">Imprimir</span>
      </v-btn>
    </v-toolbar>

    <carregando v-if="carregandoAgendamento" />

    <v-form ref="form">
      <v-container
        class="mt-4"
        @change="formularioAlterado()"
      >
        <v-row dense>
          <v-col cols="12">
            <v-select-cliente v-model="agendamentoSelecionado.cliente" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-select-produto v-model="agendamentoSelecionado.produto" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-select-tecnico v-model="agendamentoSelecionado.tecnico" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="agendamentoSelecionado.titulo"
              dense
              label="Resumo"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="agendamentoSelecionado.descricao"
              dense
              label="Descrição"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <v-input-date-time
              v-model="agendamentoSelecionado.dataAtendimento"
              dense
              label="Data de atendimento"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-rating
              v-model="agendamentoSelecionado.prioridade"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              hover
              length="5"
              value="3"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Endereço de atendimento:
          </v-col>
          <v-col cols="12">
            <v-editor-endereco :endereco="agendamentoSelecionado.endereco" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-toolbar flat>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        @click="$router.go(-1)"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="gravar"
      >
        Gravar
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import VEditorEndereco from "@/components/Endereco.vue";
import VSelectCliente from "@/components/SelectCliente.vue";
import VSelectProduto from "@/components/SelectProduto.vue";
import VSelectTecnico from "@/components/SelectTecnico.vue";
import Carregando from "@/components/Carregando.vue";
import VInputDateTime from "@/components/InputDateTime.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    Carregando,
    VEditorEndereco,
    VSelectCliente,
    VSelectProduto,
    VSelectTecnico,
    VInputDateTime,
  },
  data: () => ({
    modeloAlterado: false,
    mensagens: [],
    carregandoAgendamento: false,
    agendamentoEmBranco: {
      codigo: "",
      ativo: true,
      nome: "",
      data_cadastro: "",
      endereco: {
        ativo: true,
        nome: "Principal",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        cep: "",
      },
      telefone: "",
      celular: "",
    },
    agendamentoSelecionado: null,
  }),

  computed: {
    tituloFormulario() {
      return (
        (this.agendamentoSelecionado.id ? "Editar" : "Novo") + " Agendamento"
      );
    },
  },
  created() {
    this.agendamentoSelecionado = cloneDeep(this.agendamentoEmBranco);
  },
  mounted() {
    this.carregarAgendamento(this.$route.params.id);
  },

  beforeRouteLeave(to, from, next) {
    if (this.modeloAlterado) {
      this.$confirm("Tem certeza que seja sair sem salvar?").then((res) => {
        if (res) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    formularioAlterado() {
      this.modeloAlterado = true;
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      //teste duplo porque estava dando erro de undefined em campo dinâmico de PJ
      if (index < inputs.length && inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    },
    carregarAgendamento(id) {
      if (id > 0) {
        this.carregandoAgendamento = true;
        this.$http
          .get(`${process.env.VUE_APP_URL_API}/agendamentos/${id}`)
          .then(async (response) => {
            this.agendamentoSelecionado = response.data;
            this.carregandoAgendamento = false;
          })
          .catch((error) => {
            console.log(error);
            this.carregandoAgendamentos = false;
            return Promise.reject(error);
          });
      } else {
        this.agendamentoSelecionado = cloneDeep(this.agendamentoEmBranco);
      }
    },

    exportar() {
      console.log("Não implementado.");
    },

    gravar() {
      if (!this.validar()) {
        this.exibirMensagem({
          mensagem: "Corrija os erros destacados antes de prosseguir.",
          tipo: "error",
        });
        this.$vuetify.goTo(0);
        return;
      }

      if (this.agendamentoSelecionado.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/agendamentos/` +
              this.agendamentoSelecionado.id,
            this.agendamentoSelecionado
          )
          .then((response) => {
            this.agendamentoSelecionado = Object.assign(
              this.agendamentoSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Agendamento atualizado com sucesso. ",
            });
            this.modeloAlterado = false;

            // if (this.indiceAgendamentoSelecionado > -1) {
            //   Object.assign(
            //     this.agendamentos[this.indiceAgendamentoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.agendamentos.push(response.data);
            // }
            // this.fecharEditorAgendamento();
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_URL_API}/agendamentos`,
            this.agendamentoSelecionado
          )
          .then((response) => {
            this.agendamentoSelecionado = Object.assign(
              this.agendamentoSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Agendamento inserido com sucesso. ",
            });
            this.modeloAlterado = false;
            // if (this.indiceAgendamentoSelecionado > -1) {
            //   Object.assign(
            //     this.agendamentos[this.indiceAgendamentoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.agendamentos.push(response.data);
            // }
            // this.fecharEditorAgendamento();
          });
      }

      this.$vuetify.goTo(0);
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }

      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },

    validar() {
      return this.$refs.form.validate();
    },
  },
};
</script>
