<template>
  <div>
    <v-form ref="form">
      <v-container
        class="mt-4"
        @change="formularioAlterado()"
      >
        <v-row dense>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Dados pessoais:
          </v-col>
          <v-col cols="2">
            <v-text-field-cpf-cnpj
              v-model="pessoa.codigoNacional"
              dense
              autofocus
              label="CPF/CNPJ"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="5">
            <v-text-field
              v-model="pessoa.nome"
              dense
              :rules="regrasNome"
              label="Nome"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="pessoaJuridica"
          dense
        >
          <v-col cols="4">
            <v-text-field
              v-model="pessoa.nomeFantasia"
              dense
              label="Nome Fantasia"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              v-model="pessoa.email"
              dense
              :rules="regrasEmail"
              label="e-mail"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-input-date
              v-model="pessoa.dataNascimento"
              dense
              label="Data de nascimento"
              @keydown.enter="focusNext"
            />
          </v-col>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Endereço de cobrança:
          </v-col>
          <v-col cols="12">
            <v-editor-endereco :endereco="pessoa.endereco" />
          </v-col>
          <v-col
            cols="12"
            class="text-h6 pb-6"
          >
            Contato:
          </v-col>
          <v-col cols="2">
            <v-text-field-telefone
              v-model="pessoa.telefone"
              dense
              label="Telefone"
              @keydown.enter="focusNext"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field-telefone
              v-model="pessoa.celular"
              dense
              label="Celular"
              @keydown.enter="focusNext"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="pessoaJuridica"
          dense
        >
          <v-col cols="3">
            <v-text-field
              v-model="pessoa.contato"
              dense
              label="Nome"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import VEditorEndereco from "@/components/Endereco.vue";
import { obrigatorio, minimo10, email } from "@/helper/validadores";
import VTextFieldTelefone from "./Telefone.vue";
import VInputDate from "./InputDate.vue";
import VTextFieldCpfCnpj from "./CpfCnpj.vue";

export default {
  components: {
    VEditorEndereco,
    VTextFieldTelefone,
    VTextFieldCpfCnpj,
    VInputDate,
  },
  props: { pessoa: { type: Object, default: null }, ativo: Boolean },
  data: () => ({
    regrasNome: [obrigatorio, minimo10],
    regrasEmail: [obrigatorio, email],
    exibirMensagemValidacao: true,
    pessoaJuridica: false,
    modeloAlterado: false,
  }),
  computed: {
    tituloFormulario() {
      return (this.pessoa.id ? "Editar" : "Novo") + " Cliente";
    },
  },
  // watch: {
  //   dialog(val) {
  //     val || this.fechar();
  //   },
  // },
  methods: {
    formularioAlterado() {
      this.modeloAlterado = true;
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      //teste duplo porque estava dando erro de undefined em campo dinâmico de PJ
      if (index < inputs.length && inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    },

    validar() {
      return this.$refs.form.validate();
    },
    alterado() {
      return this.modeloAlterado;
    },
  },
};
</script>
