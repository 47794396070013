<template>
  <v-app>
    <v-navigation-drawer
      v-model="sidebar"
      app
    >
      <v-list
        class="blue darken-1"
        dense
        nav
      >
        <v-list-item>
          <v-list-item-icon>
            <v-btn
              v-show="sidebar"
              icon
              class="hidden-xs-only"
              @click="sidebar = !sidebar"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">
              Gestão VIP
            </v-list-item-title>
            <v-list-item-subtitle> {{ versaoApp }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />

      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/dashboard"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Início</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/calendario"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Calendário</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/about"
        >
          <v-list-item-content>
            <v-list-item-title>Sobre</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin"
        >
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/agendamentos"
        >
          <v-list-item-content>
            <v-list-item-title>Agendamentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/clientes"
        >
          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/empresas"
        >
          <v-list-item-content>
            <v-list-item-title>Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/fornecedores"
        >
          <v-list-item-content>
            <v-list-item-title>Fornecedores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/movimentos-estoque"
        >
          <v-list-item-content>
            <v-list-item-title>Movimentação do estoque</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/ordens-servico"
        >
          <v-list-item-content>
            <v-list-item-title>Ordens de Serviço</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/papeis"
        >
          <v-list-item-content>
            <v-list-item-title>Papéis</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/produtos"
        >
          <v-list-item-content>
            <v-list-item-title>Produtos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/tecnicos"
        >
          <v-list-item-content>
            <v-list-item-title>Técnicos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/usuarios"
        >
          <v-list-item-content>
            <v-list-item-title>Usuários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      flat
      color="blue darken-1"
    >
      <v-app-bar-nav-icon
        v-show="!sidebar"
        @click="sidebar = !sidebar"
      />

      <v-toolbar-title v-show="!sidebar">
        Gestão VIP
      </v-toolbar-title>

      <v-spacer />

      <v-menu
        v-if="usuario"
        bottom
        min-width="300px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              color="brown"
              size="48"
            >
              <img
                v-if="usuario.foto"
                :src="usuario.foto"
                :alt="usuario.nome"
              >
              <span
                v-if="!usuario.foto"
                class="text-h5"
              >{{
                usuario.iniciais
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="blue lighten-4">
                <img
                  v-if="usuario.foto"
                  :src="usuario.foto"
                  :alt="usuario.nome"
                >
                <span
                  v-if="!usuario.foto"
                  class="text-h5"
                >{{
                  usuario.iniciais
                }}</span>
              </v-avatar>
              <h3>{{ usuario.nome }}</h3>
              <p class="text-caption mt-1">
                {{ usuario.email }}
              </p>
              <v-divider class="my-3" />
              <v-btn
                depressed
                rounded
                text
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3" />
              <v-btn
                depressed
                rounded
                text
                @click="logout"
              >
                Sair <v-icon>mdi-logout</v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      sidebar: true,
      usuario: null,
    };
  },
  computed: {
    versaoApp() {
      return process.env.VUE_APP_VERSAO;
    },
  },
  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("usuario"));

    if (!this.usuario.foto) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.usuario.nome.matchAll(rgx)] || [];
      this.usuario.iniciais = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
    }
  },
  methods: {
    logout: function () {
      localStorage.removeItem("usuario", null);
      localStorage.removeItem("jwt", null);
      this.$router.push({ name: "home" });
    },
  },
};
</script>
