<template>
  <v-dialog
    v-model="ativo"
    scrollable
    persistent
    width="700"
    @keydown.esc="fechar"
  >
    <v-card>
      <v-card-title fixed>
        Pesquisa avançada
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row dense>
              <v-col cols="8">
                <v-text-field
                  v-model="parametrosPesquisa.nome"
                  dense
                  autofocus
                  label="Nome"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field-cpf-cnpj
                  v-model="parametrosPesquisa.codigoNacional"
                  dense
                  label="CPF/CNPJ"
                  :rules="[]"
                  @keydown.enter="pesquisar"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-input-date
                  v-model="parametrosPesquisa.dataNascimento"
                  dense
                  label="Data de nascimento"
                  :rules="[]"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="parametrosPesquisa.email"
                  dense
                  label="e-mail"
                  @keydown.enter="pesquisar"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  v-model="parametrosPesquisa.cep"
                  v-mask="'##.###-###'"
                  dense
                  label="CEP"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="parametrosPesquisa.logradouro"
                  dense
                  label="Endereço"
                  @keydown.enter="pesquisar"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  v-model="parametrosPesquisa.bairro"
                  dense
                  label="Bairro"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="parametrosPesquisa.municipio"
                  dense
                  label="Município"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="parametrosPesquisa.uf"
                  v-mask="'AA'"
                  dense
                  label="UF"
                  class="input-uppercase"
                  @keydown.enter="pesquisar"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-text-field-telefone
                  v-model="parametrosPesquisa.telefone"
                  dense
                  label="Telefone"
                  @keydown.enter="pesquisar"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="parametrosPesquisa.nomeContato"
                  dense
                  label="Contato"
                  @keydown.enter="pesquisar"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="fechar"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="pesquisar"
        >
          Pesquisar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VTextFieldTelefone from "./Telefone.vue";
import VTextFieldCpfCnpj from "./CpfCnpj.vue";
import VInputDate from "./InputDate.vue";

export default {
  components: {
    VTextFieldTelefone,
    VTextFieldCpfCnpj,
    VInputDate,
  },
  props: {
    parametrosPesquisa: { type: Object, default: null },
    ativo: { type: Boolean, default: false },
  },
  data: () => ({
    exibirMensagemValidacao: true,
  }),
  // watch: {
  //   dialog(val) {
  //     val || this.fechar();
  //   },
  // },
  methods: {
    pesquisar() {
      this.$emit("pesquisar", false);
    },
    fechar() {
      this.$emit("fechar", false);
    },
  },
};
</script>

<style>
.input-uppercase input {
  text-transform: uppercase;
}
</style>
