<template>
  <div>
    <v-text-field
      ref="ref"
      v-model="cmpValue"
      :label="label"
      v-bind="computedProps"
      :maxlength="18"
      :dense="dense"
      v-on="$listeners"
      @keypress="keyPress"
    />
  </div>
</template>

<script>
import { cpfOuCnpjValido } from "../helper/validadores";

export default {
  model: { prop: "value", event: "input" },
  props: {
    dense: Boolean,
    autofocus: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    properties: {
      type: Object,
      default: function () {
        return {};
      },
    },
    rules: {
      type: Array,
      default: () => [cpfOuCnpjValido],
    },
  },
  data: () => ({}),
  /*
   v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
   O valor digitado entra pelo newValue do Set é emitido para o componente pai, retorna pelo get e pára.
  */
  computed: {
    cmpValue: {
      get: function () {
        return this.formatarValor(this.value);
      },
      set: function (newValue) {
        this.$emit("input", this.removerMascara(newValue));
      },
    },
    computedProps: function () {
      return {
        ...this.$props,
      };
    },
  },
  watch: {},
  methods: {
    getInputMask: function () {
      if (this.removerMascara(this.value).length > 11)
        return "##.###.###/####-##";
      else return "###.###.###-##";
    },
    formatarValor: function (value) {
      if (value) {
        return this.formatCpfCnpj(value, this.getInputMask());
      } else {
        return "";
      }
    },

    removerMascara(value) {
      if (value) {
        return value.replace(/[^\d]+/g, "");
      } else {
        return "";
      }
    },

    formatCpfCnpj: function (value, mask) {
      value = this.removerMascara(value);
      let result = "";
      let count = 0;
      if (value) {
        let arrayValue = value.toString().split("");
        let arrayMask = mask.toString().split("");
        for (var i = 0; i < arrayMask.length; i++) {
          if (i < arrayValue.length + count) {
            if (arrayMask[i] === "#") {
              result = result + arrayValue[i - count];
            } else {
              result = result + arrayMask[i];
              count++;
            }
          }
        }
      }
      return result;
    },

    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },

    focus() {
      setTimeout(() => {
        this.$refs.ref.focus();
      }, 500);
    },
  },
};
</script>
