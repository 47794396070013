<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>

    <v-toolbar flat>
      <v-btn
        icon
        color="primary darken-1"
        class="hidden-xs-only"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloFormulario }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        small
        @click="carregarCliente($route.params.id)"
      >
        <v-icon>mdi-cached</v-icon>
        <span class="d-none d-lg-flex">Atualizar</span>
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        small
        :disabled="carregandoCliente"
        @click="exportar"
      >
        <v-icon>mdi-printer</v-icon>
        <span class="d-none d-lg-flex">Imprimir</span>
      </v-btn>
    </v-toolbar>

    <carregando v-if="carregandoCliente" />
    <v-tabs
      v-if="!carregandoCliente"
      v-model="abaSelecionada"
    >
      <v-tab> Dados Cadastrais </v-tab>
      <v-tab> Agendamentos realizados </v-tab>
    </v-tabs>
    <v-tabs-items
      v-if="!carregandoCliente"
      v-model="abaSelecionada"
    >
      <v-tab-item>
        <editor-pessoa
          ref="editorPessoa"
          :pessoa="clienteSelecionado"
        />
      </v-tab-item>
      <v-tab-item>
        <lista-agendamento
          embutido
          :cliente="clienteSelecionado"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-toolbar flat>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        @click="$router.go(-1)"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="gravar"
      >
        Gravar
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import EditorPessoa from "@/components/EditorPessoa.vue";
import ListaAgendamento from "@/views/ListaAgendamento.vue";
import Carregando from "@/components/Carregando.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    Carregando,
    EditorPessoa,
    ListaAgendamento,
  },
  data: () => ({
    abaSelecionada: null,
    mensagens: [],
    carregandoCliente: false,
    clienteEmBranco: {
      codigo: "",
      ativo: true,
      nome: "",
      data_cadastro: "",
      endereco: {
        ativo: true,
        nome: "Principal",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        cep: "",
      },
      telefone: "",
      celular: "",
    },
    clienteSelecionado: null,
  }),

  computed: {
    tituloFormulario() {
      return (this.clienteSelecionado.id ? "Editar" : "Novo") + " Cliente";
    },
  },
  created() {
    this.clienteSelecionado = cloneDeep(this.clienteEmBranco);
  },
  mounted() {
    this.carregarCliente(this.$route.params.id);
  },

  beforeRouteLeave(to, from, next) {
    if (this.$refs.editorPessoa.alterado()) {
      this.$confirm("Tem certeza que seja sair sem salvar?").then((res) => {
        if (res) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    carregarCliente(id) {
      if (id > 0) {
        this.carregandoCliente = true;
        this.$http
          .get(`${process.env.VUE_APP_URL_API}/clientes/${id}`)
          .then((response) => {
            this.clienteSelecionado = response.data;
            this.carregandoCliente = false;
          })
          .catch((error) => {
            console.log(error);
            this.carregandoCliente = false;
            return Promise.reject(error);
          });
      } else {
        this.clienteSelecionado = cloneDeep(this.clienteEmBranco);
      }
    },

    exportar() {
      console.log("Não implementado.");
    },

    gravar() {
      if (!this.$refs.editorPessoa.validar()) {
        this.exibirMensagem({
          mensagem: "Corrija os erros destacados antes de prosseguir.",
          tipo: "error",
        });
        this.$vuetify.goTo(0);
        return;
      }

      if (this.clienteSelecionado.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/clientes/` +
              this.clienteSelecionado.id,
            this.clienteSelecionado
          )
          .then((response) => {
            this.clienteSelecionado = Object.assign(
              this.clienteSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Cliente atualizado com sucesso. ",
            });
            // if (this.indiceClienteSelecionado > -1) {
            //   Object.assign(
            //     this.clientes[this.indiceClienteSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.clientes.push(response.data);
            // }
            // this.fecharEditorCliente();
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_URL_API}/clientes`,
            this.clienteSelecionado
          )
          .then((response) => {
            this.clienteSelecionado = Object.assign(
              this.clienteSelecionado,
              response.data
            );
            this.exibirMensagem({ mensagem: "Cliente inserido com sucesso. " });
            // if (this.indiceClienteSelecionado > -1) {
            //   Object.assign(
            //     this.clientes[this.indiceClienteSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.clientes.push(response.data);
            // }
            // this.fecharEditorCliente();
          });
      }

      this.$vuetify.goTo(0);
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }

      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
