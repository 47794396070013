<template>
  <v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-sheet
              class="d-flex align-center justify-center pa-4 mx-auto"
              max-width="550"
              min-height="150"
            >
              <div class="text-h3 mb-1 text-center">
                Gestão VIP
              </div>
            </v-sheet>
            <v-snackbar
              v-model="exibirMensagemErro"
              :timeout="-1"
              top
              :width="'95%'"
              :color="'error'"
            >
              <span
                v-for="texto in mensagens"
                :key="texto"
                class="d-block"
              >{{
                texto
              }}</span>

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="dark"
                  text
                  v-bind="attrs"
                  @click="exibirMensagemErro = false"
                >
                  Fechar
                </v-btn>
              </template>
            </v-snackbar>

            <v-card class="elevation-12">
              <v-card-text>
                <v-form>
                  <div class="text-h6 mb-3 text-center">
                    Fazer login no Gestão VIP
                  </div>
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account"
                    name="email"
                    label="Usuário"
                    type="text"
                  />
                  <v-text-field
                    id="senha"
                    v-model="senha"
                    prepend-icon="mdi-lock"
                    name="senha"
                    label="Senha"
                    type="password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="handleSubmit"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="elevation-12">
              <v-card-text>
                <a :href="facebookLoginUrl">Entrar com o facebook</a>
              </v-card-text>
            </v-card>
            <v-card class="elevation-12">
              <v-card-text>
                <a :href="googleLoginUrl">Entrar com o Google</a>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import * as queryString from "query-string";

export default {
  data() {
    return {
      email: "",
      senha: "",
      facebookLoginUrl: "",
      googleLoginUrl: "",
      exibirMensagemErro: false,
      mensagens: [],
    };
  },
  mounted() {
    const paramFacebook = queryString.stringify({
      client_id: process.env.VUE_APP_ID_FACEBOOK,
      redirect_uri: `${process.env.VUE_APP_URL}/login/facebook`,
      scope: ["email"].join(","), // comma seperated string
      response_type: "code",
      auth_type: "rerequest",
      display: "popup",
      state: "gabagabarey",
    });

    console.log(paramFacebook);
    console.log(`${process.env.VUE_APP_ID_FACEBOOK}`);

    this.facebookLoginUrl = `https://www.facebook.com/v11.0/dialog/oauth?${paramFacebook}`;

    const paramGoogle = queryString.stringify({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${process.env.VUE_APP_URL}/login/google`,
      scope: [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
      ].join(" "), // space seperated string
      response_type: "code",
      access_type: "offline",
      prompt: "consent",
    });

    console.log(paramGoogle);
    console.log(`${process.env.VUE_APP_GOOGLE_CLIENT_ID}`);

    this.googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${paramGoogle}`;

    const urlParams = queryString.parse(window.location.search);

    if (urlParams) {
      //retorno de erro do Facebook
      if (urlParams.error_message) {
        this.exibirMensagem([urlParams.error_message]);
      }
      //retorno de erro do Google
      if (urlParams.error) {
        this.exibirMensagem([urlParams.error]);
      }

      console.log("OAuth selecionado: ", this.$route.params.oauth);
      if (urlParams.code) {
        console.log(urlParams.code);
        this.$http
          .get(
            `${process.env.VUE_APP_URL_API}/login/${this.$route.params.oauth}`,
            {
              params: { code: urlParams.code },
            }
          )
          .then((response) => {
            this.tratarLogin(response);
          })
          .catch((error) => {
            this.tratarErro(error);
          });
      }
    }
  },
  methods: {
    exibirMensagem: function (mensagens) {
      this.mensagens = mensagens;
      this.exibirMensagemErro = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      if (this.senha.length > 0) {
        this.$http
          .post(`${process.env.VUE_APP_URL_API}/login`, {
            email: this.email,
            senha: this.senha,
          })
          .then((response) => {
            this.tratarLogin(response);
          })
          .catch((error) => {
            this.tratarErro(error);
          });
      } else {
        this.exibirMensagem("Usuário e senha são obrigatórios.");
      }
    },
    tratarLogin(response) {
      let is_admin = response.data.usuario.is_admin;
      localStorage.setItem("usuario", JSON.stringify(response.data.usuario));
      localStorage.setItem("jwt", response.data.token);

      if (localStorage.getItem("jwt") != null) {
        this.$emit("loggedIn");
        if (this.$route.params.nextUrl != null) {
          this.$router.push(this.$route.params.nextUrl);
        } else {
          if (is_admin == 1) {
            this.$router.push("admin");
          } else {
            this.$router.push("dashboard");
          }
        }
      }
    },
    tratarErro(error) {
      if (!error.response) {
        this.exibirMensagem([
          "Não foi possível estabelecer a comunicação com o servidor. ",
          "Verifique se você está conectado na internet.",
        ]);
        return Promise.reject(error);
      }

      switch (error.response.status) {
        case 401:
          console.error(error.response.status, error.message);
          localStorage.removeItem("jwt");
          localStorage.removeItem("usuario");
          this.$router.push("/login").catch(() => {});
          break;

        case 404:
          console.error(error.response.status, error.message);
          this.exibirMensagem(["O recurso solicitado não existe."]);
          break;

        default:
          console.error(error.response.status, error.message);
          if (!!error.response.data && !!error.response.data.mensagem) {
            this.exibirMensagem([error.response.data.mensagem]);
          } else if (error.response.data.erros) {
            this.exibirMensagem(error.response.data.erros);
          } else {
            console.log(error.response.data);

            if (error.response.data instanceof Blob) {
              if (
                (error.response.data.type === "text/html" ||
                  error.response.data.type === "text/plain") &&
                error.response.data.size < 1024
              ) {
                const reader = new FileReader();
                reader.addEventListener("loadend", (e) => {
                  const mensagem = e.target.result;
                  this.exibirMensagem([mensagem]);
                });
                reader.readAsText(error.response.data);
              } else {
                this.exibirMensagem([
                  "Ocorreu um erro ao processar a requisição.",
                ]);
              }
            } else {
              this.exibirMensagem([error.response.data]);
            }
          }
      }
    },
  },
};
</script>
