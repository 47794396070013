var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.dataSelecionada),callback:function ($$v) {_vm.dataSelecionada=$$v},expression:"dataSelecionada"}}),_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.filtrarEventos},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}},[_vm._l((_vm.tecnicos),function(item){return [(!item)?_c('v-divider',{key:("divider-" + (item.id))}):_c('v-list-item',{key:item.id,attrs:{"value":item.nome,"color":_vm.colors[_vm.tecnicos.findIndex(function (tec) { return item.nome == tec.nome; })]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",domProps:{"textContent":_vm._s(item.nome)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"deep-purple accent-4"}})],1)]}}],null,true)})]})],2)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Hoje ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'category'}}},[_c('v-list-item-title',[_vm._v("Diário por técnico")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Dia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Mês")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 dias")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":_vm.type,"category-show-all":"","categories":_vm.categories,"events":_vm.eventosVisiveis,"event-color":_vm.getEventColor,"first-time":"06:45","interval-minutes":15,"interval-count":53,"short-intervals":false,"category-for-invalid":"Outros eventos"},on:{"change":_vm.fetchEvents,"click:time":_vm.clickTime,"click:time-category":_vm.startTimeCategory,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
var category = ref.category;
return [_c('div',{staticClass:"v-current-time",class:{
              first:
                date === week[0].date &&
                (!category || category.categoryName == _vm.categories[0]),
            },style:({ top: _vm.nowY })})]}},{key:"event",fn:function(ref){
            var event = ref.event;
            var timed = ref.timed;
            var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"v-event-draggable",domProps:{"innerHTML":_vm._s(eventSummary())}}),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}]),model:{value:(_vm.dataSelecionada),callback:function ($$v) {_vm.dataSelecionada=$$v},expression:"dataSelecionada"}})],1),_c('editor-evento',{ref:"editorEvento",attrs:{"agendamento":_vm.eventoSelecionado.agendamento,"ativo":_vm.exibirEditorEvento},on:{"fechar":function($event){return _vm.fecharEditorEvento(false)},"gravar":function($event){return _vm.gravarEvento()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }