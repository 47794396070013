<template>
  <v-input
    v-model="value"
    :readonly="readonly"
    :disabled="disabled"
    :hide-details="hideDetails"
    :rules="rules"
    :dense="dense"
    :error.sync="error"
    :error-messages="errorMessages"
    :persistent-hint="persistentHint"
    :hint="hint"
  >
    <v-text-field
      ref="input"
      v-model="day"
      :label="label"
      :readonly="readonly"
      :disabled="disabled"
      :error="error"
      hide-details
      :dense="dense"
      :filled="filled"
      :outlined="outlined"
      type="date"
      @focus="enterInput"
      @blur="sendInput"
      v-on="$listeners"
    />
  </v-input>
</template>

<script>
import { VInput, VTextField } from "vuetify/lib";

export default {
  name: "VuetifyDatetimeInput",
  components: {
    "v-input": VInput,
    "v-text-field": VTextField,
  },
  props: {
    value: { type: String, default: "" },
    readonly: Boolean,
    disabled: Boolean,
    hideDetails: Boolean,
    dense: Boolean,
    filled: Boolean,
    outlined: Boolean,
    clearable: Boolean,
    persistentHint: Boolean,
    hint: { type: String, default: "" },
    label: { type: String, default: "" },
    incompleteErrorMessage: {
      type: String,
      default: () => "Data inválida.",
    },
    dateformatErrorMessage: {
      type: String,
      default: () => "Data inválida.",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    day: null,
    error: false,
    errorMessages: "",
  }),
  watch: {
    value: function () {
      this.error = false;
      this.errorMessages = "";
      this.parseValue();
    },
  },
  mounted() {
    this.parseValue();
  },
  methods: {
    getDate() {
      if (this.day) {
        this.error = false;
        this.errorMessages = "";
        let date = new Date(this.day);

        return date.toISOString();
      }
      return null;
    },
    parseValue() {
      if (this.value) {
        let newDate = new Date(this.value);
        this.day = newDate.toISOString().replace(/T.*/, "");
      } else {
        this.day = null;
      }
    },
    enterInput() {
      if (!this.day) {
        this.error = false;
        this.errorMessages = "";
      }
    },
    sendInput() {
      if (this.day) {
        try {
          let newDate = this.getDate();
          this.$emit("input", newDate);
        } catch (e) {
          this.error = true;
          this.errorMessages = this.dateformatErrorMessage;
        }
      }
    },
  },
};
</script>
