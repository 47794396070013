<template>
  <v-row class="fill-height">
    <v-col cols="3">
      <v-date-picker
        v-model="dataSelecionada"
        no-title
      />
      <v-list shaped>
        <v-list-item-group
          v-model="categories"
          multiple
          @change="filtrarEventos"
        >
          <template v-for="item in tecnicos">
            <v-divider
              v-if="!item"
              :key="`divider-${item.id}`"
            />

            <v-list-item
              v-else
              :key="item.id"
              :value="item.nome"
              :color="
                colors[tecnicos.findIndex((tec) => item.nome == tec.nome)]
              "
            >
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap"
                    v-text="item.nome"
                  />
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="deep-purple accent-4"
                  />
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="9">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Hoje
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer />
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'category'">
                <v-list-item-title>Diário por técnico</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 dias</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="dataSelecionada"
          color="primary"
          :type="type"
          category-show-all
          :categories="categories"
          :events="eventosVisiveis"
          :event-color="getEventColor"
          first-time="06:45"
          :interval-minutes="15"
          :interval-count="53"
          :short-intervals="false"
          category-for-invalid="Outros eventos"
          @change="fetchEvents"
          @click:time="clickTime"
          @click:time-category="startTimeCategory"
          @click:more="viewDay"
          @click:date="viewDay"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        >
          <template v-slot:day-body="{ date, week, category }">
            <div
              class="v-current-time"
              :class="{
                first:
                  date === week[0].date &&
                  (!category || category.categoryName == categories[0]),
              }"
              :style="{ top: nowY }"
            />
          </template>
          <!-- eslint-disable vue/no-v-html -->
          <template v-slot:event="{ event, timed, eventSummary }">
            <div
              class="v-event-draggable"
              v-html="eventSummary()"
            />
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            />
          </template>
        </v-calendar>
      </v-sheet>
      <editor-evento
        ref="editorEvento"
        :agendamento="eventoSelecionado.agendamento"
        :ativo="exibirEditorEvento"
        @fechar="fecharEditorEvento(false)"
        @gravar="gravarEvento()"
      />
    </v-col>
  </v-row>
</template>

<script>
import EditorEvento from "@/components/EditorEvento.vue";
import moment from "moment";
import { cloneDeep } from "lodash";

export default {
  components: {
    EditorEvento,
  },
  data: () => ({
    firstLoad: true,
    type: "category",
    typeToLabel: {
      category: "Diário por técnico",
      month: "Mês",
      week: "Semana",
      day: "Dia",
      "4day": "4 dias",
    },
    dataSelecionada: "",
    dragEvent: null,
    dragStart: null,
    eventoSelecionado: null,
    createStart: null,
    extendOriginal: null,
    calendarioMontado: false,
    exibirEditorEvento: false,
    carregandoCalendario: true,
    events: [],
    eventosFiltrados: [],
    colors: [
      "blue",
      "green",
      "orange",
      "indigo",
      "purple",
      "teal",
      "lime",
      "cyan",
      "red",
      "grey",
      "deep-purple",
    ],
    categories: [],
    tecnicos: [],
    eventoEmBranco: {
      name: "",
      start: null,
      end: null,
      timed: null,
      category: null,
      color: null,
      agendamento: {
        ativo: true,
        titulo: "",
        descricao: "",
        dataAtendimento: null,
        duracao: 15, //em minutos
        diaInteiro: false,
        prioridade: null,
        endereco: {
          ativo: true,
          nome: "Principal",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          cep: "",
        },
      },
    },
  }),
  computed: {
    cal() {
      return this.calendarioMontado ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    eventosVisiveis() {
      console.log("Filtrando eventos");
      if (
        this.type == "category" ||
        this.tecnicos.length == this.categories.length ||
        this.categories.length == 0
      ) {
        return this.events;
      } else {
        return this.eventosFiltrados;
      }
    },
  },
  created() {
    this.eventoSelecionado = cloneDeep(this.eventoEmBranco);
  },
  mounted() {
    this.calendarioMontado = true;
    this.$refs.calendar.checkChange();
    this.scrollToTime();
    this.updateTime();
  },
  methods: {
    viewDay({ date }) {
      this.dataSelecionada = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.dataSelecionada = moment().format("YYYY-MM-DD");
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    fetchEvents({ start, end }) {
      this.events = [];
      this.eventosFiltrados = [];
      //this.tecnicos = [];
      //this.categories = [];
      this.carregandoCalendario = true;
      this.$http
        .get(`${process.env.VUE_APP_URL_API}/calendario`, {
          params: { dataInicial: start.date, dataFinal: end.date },
        })
        .then(async (response) => {
          this.tecnicos = response.data.tecnicos;
          if (response.data.tecnicos.length <= 10 && this.firstLoad) {
            this.categories = response.data.tecnicos.map((item) => item.nome);
          }
          this.events = response.data.eventos.map((item) =>
            this.gerarEvento(item)
          );
          this.filtrarEventos();
          this.carregandoCalendario = false;
          this.firstLoad = false;
        })
        .catch((error) => {
          console.log(error);
          this.carregandoCalendario = false;
          return Promise.reject(error);
        });
    },
    gerarEvento(item) {
      return {
        name: item.titulo,
        start: moment(item.dataAtendimento).toDate(),
        end: moment(item.dataAtendimento).add(item.duracao, "minutes").toDate(),
        timed: !item.diaInteiro,
        category: item.tecnico.nome,
        color:
          this.colors[
            this.tecnicos.findIndex((tec) => item.tecnico.id == tec.id)
          ],
        agendamento: item,
      };
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.eventoSelecionado = event;
        console.log(this.eventoSelecionado);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.exibirEditorEvento = true))
        );
      };

      if (this.exibirEditorEvento) {
        this.exibirEditorEvento = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    clickTime() {
      // if (this.categories && this.categories.length > 0) return;
      // this.novoEvento(param);
    },
    clickTimeCategory() {
      // if (!this.categories || this.categories.length == 0) return;
      // this.novoEvento(param);
    },
    novoEvento(param) {
      const inicio = this.roundTime(this.toTime(param));
      const termino = moment(inicio).add(15, "minutes").toDate();

      const event = cloneDeep(this.eventoEmBranco);
      console.log("Evento clonado:", event);
      event.start = inicio;
      event.end = termino;
      event.timed = true;
      event.color = this.colors[0];

      if (param.category) {
        event.category = param.category.categoryName;
        event.color =
          this.colors[
            this.tecnicos.findIndex(
              (tec) => param.category.categoryName == tec.nome
            )
          ];
        event.agendamento.tecnico = this.tecnicos.find(
          (tec) => param.category.categoryName == tec.nome
        );
      }
      event.agendamento.dataAtendimento = moment(inicio).toISOString();

      console.log("Novo evento:", event);
      //this.showEvent({ event });
      return event;
    },
    gravarEvento() {
      if (this.eventoSelecionado.agendamento.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/agendamentos/` +
              this.eventoSelecionado.agendamento.id,
            this.eventoSelecionado.agendamento
          )
          .then((response) => {
            if (this.eventoSelecionado) {
              this.eventoSelecionado = Object.assign(
                this.eventoSelecionado,
                this.gerarEvento(response.data)
              );
            }
            this.fecharEditorEvento(true);
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_URL_API}/agendamentos`,
            this.eventoSelecionado.agendamento
          )
          .then((response) => {
            if (this.eventoSelecionado) {
              this.eventoSelecionado = Object.assign(
                this.eventoSelecionado,
                this.gerarEvento(response.data)
              );
            } else {
              this.events.push(this.gerarEvento(response.data));
            }
            this.fecharEditorEvento(true);
          });
      }
    },
    fecharEditorEvento(gravou) {
      this.exibirEditorEvento = false;
      if (!gravou && !this.eventoSelecionado.agendamento.id) {
        this.events.splice(this.events.indexOf(this.eventoSelecionado, 1));
      }
      this.$nextTick(() => {
        this.eventoSelecionado = cloneDeep(this.eventoEmBranco);
      });
    },
    startDrag({ event, timed }) {
      console.log("startDrag");
      if (event && timed) {
        this.dragEvent = event;
        this.eventoSelecionado = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(param) {
      console.log("startTime");
      console.log(param);
      const mouse = this.toTime(param);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.eventoSelecionado = this.novoEvento(param);
        this.events.push(this.eventoSelecionado);
        //this.showEvent({ event: this.eventoSelecionado });
      }
    },
    startTimeCategory(param) {
      console.log("startTimeCategory");
      if (
        !this.eventoSelecionado.category &&
        param.category &&
        param.category.categoryName
      ) {
        this.eventoSelecionado.category = param.category.categoryName;
        this.eventoSelecionado.color =
          this.colors[
            this.tecnicos.findIndex(
              (tec) => param.category.categoryName == tec.nome
            )
          ];
        this.eventoSelecionado.agendamento.tecnico = this.tecnicos.find(
          (tec) => param.category.categoryName == tec.nome
        );
      }
    },
    extendBottom(event) {
      console.log("extendBottom");
      this.eventoSelecionado = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      // console.log("mouseMove");
      //console.log("mouseMove", tms);
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.eventoSelecionado && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.eventoSelecionado.start = min;
        this.eventoSelecionado.end = max;
      }
    },
    endDrag() {
      console.log("endDrag");
      if (this.eventoSelecionado) {
        console.log("showEvent");
        console.log(moment(this.eventoSelecionado.start).toISOString());
        const novaDataInicio = moment(
          this.eventoSelecionado.start
        ).toISOString();
        const novaDuracao = moment
          .duration(
            moment(this.eventoSelecionado.end).diff(
              this.eventoSelecionado.start
            )
          )
          .asMinutes();

        const eventoMovido =
          novaDataInicio !=
            this.eventoSelecionado.agendamento.dataAtendimento ||
          novaDuracao != this.eventoSelecionado.agendamento.duracao;

        if (this.eventoSelecionado.agendamento.id && eventoMovido) {
          this.eventoSelecionado.agendamento.dataAtendimento = novaDataInicio;
          this.eventoSelecionado.agendamento.duracao = novaDuracao;
          console.log("Gravando evento");
          this.gravarEvento();
        } else {
          console.log("Exibindo evento");
          this.showEvent({ event: this.eventoSelecionado });
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      //this.eventoSelecionado = cloneDeep(this.eventoEmBranco);
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      console.log("cancelDrag");
      if (this.eventoSelecionado && this.extendOriginal) {
        this.eventoSelecionado.end = this.extendOriginal;
      }

      //this.eventoSelecionado = cloneDeep(this.eventoEmBranco);
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    filtrarEventos() {
      console.log("Aplicando filtro");
      this.categories = this.categories.sort();
      this.eventosFiltrados = this.events.filter((item) =>
        this.categories.includes(item.category)
      );
    },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
