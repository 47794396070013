<template>
  <div>
    <v-alert
      v-for="item in mensagens"
      :key="item.timestamp"
      dense
      text
      :type="item.tipo"
    >
      {{ item.mensagem
      }}<a
        v-if="item.callback"
        href="#"
        class="text-decoration-none"
        @click="item.callback(item)"
      >{{ item.acao }}</a>
    </v-alert>

    <v-toolbar flat>
      <v-btn
        icon
        color="primary darken-1"
        class="hidden-xs-only"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloFormulario }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        small
        @click="carregarTecnico($route.params.id)"
      >
        <v-icon>mdi-cached</v-icon>
        <span class="d-none d-lg-flex">Atualizar</span>
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        small
        :disabled="carregandoTecnico"
        @click="exportar"
      >
        <v-icon>mdi-printer</v-icon>
        <span class="d-none d-lg-flex">Imprimir</span>
      </v-btn>
    </v-toolbar>

    <carregando v-if="carregandoTecnico" />
    <editor-pessoa
      v-if="!carregandoTecnico"
      ref="editorPessoa"
      :pessoa="tecnicoSelecionado"
    />
    <v-toolbar flat>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        @click="$router.go(-1)"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="gravar"
      >
        Gravar
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import EditorPessoa from "@/components/EditorPessoa.vue";
import Carregando from "@/components/Carregando.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    Carregando,
    EditorPessoa,
  },
  data: () => ({
    mensagens: [],
    carregandoTecnico: false,
    tecnicoEmBranco: {
      codigo: "",
      ativo: true,
      nome: "",
      data_cadastro: "",
      endereco: {
        ativo: true,
        nome: "Principal",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        cep: "",
      },
      telefone: "",
      celular: "",
    },
    tecnicoSelecionado: null,
  }),

  computed: {
    tituloFormulario() {
      return (this.tecnicoSelecionado.id ? "Editar" : "Novo") + " Usuário";
    },
  },
  created() {
    this.tecnicoSelecionado = cloneDeep(this.tecnicoEmBranco);
  },
  mounted() {
    this.carregarTecnico(this.$route.params.id);
  },

  beforeRouteLeave(to, from, next) {
    if (this.$refs.editorPessoa.alterado()) {
      this.$confirm("Tem certeza que seja sair sem salvar?").then((res) => {
        if (res) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    carregarTecnico(id) {
      if (id > 0) {
        this.carregandoTecnico = true;
        this.$http
          .get(`${process.env.VUE_APP_URL_API}/tecnicos/${id}`)
          .then(async (response) => {
            this.tecnicoSelecionado = response.data;
            this.carregandoTecnico = false;
          })
          .catch((error) => {
            console.log(error);
            this.carregandoTecnico = false;
            return Promise.reject(error);
          });
      } else {
        this.tecnicoSelecionado = cloneDeep(this.tecnicoEmBranco);
      }
    },

    exportar() {
      console.log("Não implementado.");
    },

    gravar() {
      if (!this.$refs.editorPessoa.validar()) {
        this.exibirMensagem({
          mensagem: "Corrija os erros destacados antes de prosseguir.",
          tipo: "error",
        });
        this.$vuetify.goTo(0);
        return;
      }

      if (this.tecnicoSelecionado.id) {
        this.$http
          .put(
            `${process.env.VUE_APP_URL_API}/tecnicos/` +
              this.tecnicoSelecionado.id,
            this.tecnicoSelecionado
          )
          .then((response) => {
            this.tecnicoSelecionado = Object.assign(
              this.tecnicoSelecionado,
              response.data
            );
            this.exibirMensagem({
              mensagem: "Usuário atualizado com sucesso. ",
            });
            // if (this.indiceTecnicoSelecionado > -1) {
            //   Object.assign(
            //     this.tecnicos[this.indiceTecnicoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.tecnicos.push(response.data);
            // }
            // this.fecharEditorTecnico();
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_URL_API}/tecnicos`,
            this.tecnicoSelecionado
          )
          .then((response) => {
            this.tecnicoSelecionado = Object.assign(
              this.tecnicoSelecionado,
              response.data
            );
            this.exibirMensagem({ mensagem: "Usuário inserido com sucesso. " });
            // if (this.indiceTecnicoSelecionado > -1) {
            //   Object.assign(
            //     this.tecnicos[this.indiceTecnicoSelecionado],
            //     response.data
            //   );
            // } else {
            //   this.tecnicos.push(response.data);
            // }
            // this.fecharEditorTecnico();
          });
      }

      this.$vuetify.goTo(0);
    },

    exibirMensagem(item) {
      if (!item.tipo) {
        item.tipo = "success";
      }

      item.timestamp = new Date().getTime();
      this.mensagens.push(item);
      setTimeout(() => {
        this.mensagens.splice(this.mensagens.indexOf(item), 1);
      }, 10000);
    },
  },
};
</script>
