<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    open-on-clear
    item-text="nome"
    return-object
    label="Técnico"
    @input="$emit('input', $event)"
    @keydown="buscarTecnicos"
    v-on="$listeners"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Digite um nome ou CPF/CNPJ para iniciar a pesquisa
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        class="headline font-weight-light white--text"
      >
        {{ item.nome.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.nome" />
        <v-list-item-subtitle v-text="item.symbol" />
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-bitcoin</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  data: () => ({
    timerId: null,
    isLoading: false,
    items: [],
    search: null,
  }),

  watch: {
    value(novo) {
      console.log("No value", novo);
      if (!novo) return;

      if (this.items.indexOf(novo) == -1) {
        this.items.push(novo);
      }
    },
  },

  mounted() {
    if (!this.value) return;

    if (this.items.indexOf(this.value) == -1) {
      this.items.push(this.value);
    }
  },

  methods: {
    buscarTecnicos() {
      console.log("No search", this.search);
      // cancel pending call
      clearTimeout(this.timerId);

      if (!this.search) {
        return;
      }

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        console.log("Realizando busca: ", this.search);

        this.isLoading = true;

        this.$http
          .get(`${process.env.VUE_APP_URL_API}/tecnicos`, {
            params: {
              pagina: 1,
              textoBusca: this.search,
              ordenar: "nome",
            },
          })
          .then((response) => {
            this.items = response.data.tecnicos;
          })
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },
};
</script>
